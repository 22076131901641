import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './Navbar.scss';

class Navbar extends React.Component {

    render() {
        const { loggedIn, user } = this.props;

        return (
            <header className="component-navbar">
                <nav className="nav">
                    <ul className="left">
                        <li className="navlink logo">
                            <Link to="/">
                                <img src="/c20.png" alt="Centerling News Logo"/>
                                <span>Centerling News</span>
                            </Link>
                        </li>
                        <li className="navlink">
                            <Link to="/">trending</Link>
                        </li>
                        <li className="navlink">
                            <Link to="/newest">new</Link>
                        </li>
                        <li className="navlink">
                            <Link to="/submit">submit</Link>
                        </li>
                    </ul>
                    <ul className="right">
                        {loggedIn &&
                            <li className="navlink">
                                <Link to="/profile">{user.username}</Link>
                            </li>
                        }
                        {loggedIn &&
                            <li className="navlink">
                                <Link to="/logout">logout</Link>
                            </li>
                        }
                        {!loggedIn &&
                            <li className="navlink">
                                <Link to="/login">login</Link>
                            </li>
                        }

                    </ul>
                </nav>
            </header>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    return {
        loggedIn,
        user
    };
}

const connectedNavbar = connect(mapStateToProps)(Navbar);
export { connectedNavbar as Navbar }; 
