import { postConstants } from '../constants';
import { commentService } from '../services';

export const commentActions = {
    createComment,
};

// -----------------
// actions
// -----------------

function createComment(post_id, text, callbackOnSuccess) {

    return dispatch => {
        dispatch(request());

        commentService.createComment(post_id, text)
            .then(
                response => {
                    const updatedPost = response.data;
                    dispatch(success(updatedPost)); // update the post with the new comment(s)
                    callbackOnSuccess();
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(post_id) { return { type: postConstants.UPDATE_POST_REQUEST_INITIATED, post_id } }
    function success(post) { return { type: postConstants.UPDATE_POST_REQUEST_SUCCEEDED, post } }
    function failure(error, post_id) { return { type: postConstants.UPDATE_POST_REQUEST_FAILED, error, post_id } }
}