import { authHeader } from '../../helpers';

const API_URL = process.env.REACT_APP_API_HOST + '/api'

export const postService = {
    createPost,
    getAll,
    getDetail,
    deletePost,
    updatePost,
};

// -----------------
// private functions
// -----------------

// For other responses a 401 error means that the JWT is expired
// If this is the case, delete the use and perform a hard reload the page
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            if (response.status === 401) {
                // auto-logout if 401 response returned from api
                localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_FOR_USER);
                window.location.reload(true);
            }

            const error = data
            return Promise.reject(error);
        }

        return data;
    });
}

// -----------------
// service functions
// -----------------
function createPost(title, url) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ title, url })
    };

    return fetch(`${API_URL}/posts`, requestOptions)
        .then(handleResponse);
}


function getAll(page, resultsPerPage, newest) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/posts?page=${page || 1}&results=${resultsPerPage || 25}&newest=${newest ? 1 : 0}`, requestOptions).then(handleResponse);
}

function getDetail(post_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/posts/${post_id}`, requestOptions).then(handleResponse);
}

function deletePost(post_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${API_URL}/posts/${post_id}`, requestOptions)
        .then(handleResponse)
}

function updatePost(post_id, title, url) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ title, url })
    };

    return fetch(`${API_URL}/posts/${post_id}`, requestOptions)
        .then(handleResponse)
}
