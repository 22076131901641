import React from 'react';
import { connect } from 'react-redux';

import qs from 'qs';
import { history } from '../../helpers';

import { postActions } from '../../store/actions';
import { commentActions } from '../../store/actions';

import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { PostRow } from '../../components/PostRow';
import { CommentRow } from '../../components/CommentRow';

import './PostDetailPage.scss';
import { postConstants } from '../../store/constants';

class PostDetailPage extends React.Component {
    constructor(props) {
        super(props);
        
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const post_id = this.props.match.params.post_id;

        this.state = {
            post_id: post_id,
            submitted: false,
            comment: query.comment || '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { post_id} = this.state;
        this.props.dispatch(postActions.getDetail(post_id));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { comment, post_id } = this.state;
        const { dispatch, loggedIn } = this.props;

        if (!loggedIn) {
            const postDetailLink = encodeURIComponent(`/posts/${post_id}?comment=${comment}`)
            history.push(`/login?redirect=${postDetailLink}`)
            return;
        }

        if (this.handleLocalErrors()) {
            dispatch(commentActions.createComment(post_id, comment,()=>{
                this.setState({ comment: '' });
                history.push(`/posts/${post_id}`); // make sure we get rid of the comment= query param 
            }));
        }
    }

    handleLocalErrors() {
        const { comment } = this.state;
        const { dispatch, user } = this.props;

        let validationErrors = [];

        if (!user) {
            function failure(error) { return { type: postConstants.GET_POSTS_REQUEST_FAILED, error } }
            dispatch(failure({
                status: 0,
                message: 'Please sign in to submit comments.',
                data: null
            }));
            return false
        }

        if (!comment) {
            validationErrors.push({
                value: comment,
                msg: 'Comment is required.',
                param: 'comment',
                location: 'local'
            })
        }

        if (validationErrors.length > 0 ) {
            function failure(error) { return { type: postConstants.GET_POSTS_REQUEST_FAILED, error } }
            dispatch(failure({
                status: 0,
                message: 'Local Validation Error.',
                data: validationErrors
            }));
            return false
        }

        return true; 
    }

    selectedPost() {
        const { posts } = this.props;
        const { post_id} = this.state;
        if (!posts || !posts.posts) {
            return null;
        }
        return posts.posts.find(p => post_id === p._id);
    }

    errorsForField(name) {
        const { errors } = this.props;

        if (!errors) {
            return '';
        }
        if (!errors.data) {
            return '';
        }
        if (!errors.data.length > 0) {
            return '';
        }
        return errors.data
            .filter(e => e.param === name)
            .map(e => <div className="error" key={e.msg}>{e.msg}</div>)
    }

    showFieldIndepenentErrorMessage() {
        const { errors } = this.props;
        // only show the field-independet error message, if the are none related to a specific field
        return errors && errors.message && (!errors.data || !errors.data.length);
    }

    renderComments() {
        const post = this.selectedPost();

        if (!post) {
            return '';
        }
        if (!post.comments) {
            return '';
        }
        if (!post.comments.length > 0) {
            return '';
        }
        return post.comments
            .sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map(c => <CommentRow comment={c} key={c._id} />);
    }

    render() {
        const { posts, errors} = this.props;
        const { comment, submitted } = this.state;
        const post = this.selectedPost();

        return (
            <div className={`view-post-detail-page`}>
                <Navbar/>
                <div className="post-detail-container">
                    {post&&
                        <PostRow post={post} key={post._id} />
                    }
                    {post &&
                        <div>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className={'form-group' + (submitted && !comment ? ' has-error' : '')}>
                                    <textarea type="text" className="form-control" name="comment" value={comment} onChange={this.handleChange} />
                                    {
                                        this.errorsForField('comment')
                                    }
                                </div>
                                <div className="form-group submit">
                                    {(!posts || !posts.loading) &&
                                        <input type="submit" className="form-control" name="submit" value="Submit" disabled={!comment || !comment.length}/>
                                    }
                                    {(posts && posts.loading) &&
                                        <input type="submit" className="form-control" name="submit" value="Submitting ..." disabled/>
                                    }
                                </div>
                            </form>
                            <div className="error-container">
                                {this.showFieldIndepenentErrorMessage() && 
                                    <div className="error">{errors.message}</div>
                                }
                            </div>
                        </div>
                    }
                    <div className="comment-list-container">
                        {
                            this.renderComments()
                        }
                        </div>
                    </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { login, posts } = state;

    return {
        user: login.user,
        loggedIn: login.loggedIn,
        posts: posts,
        errors: posts.errors
    };
}

const connectedPostDetailPage = connect(mapStateToProps)(PostDetailPage);
export { connectedPostDetailPage as PostDetailPage };
