import React from 'react';
import { connect } from 'react-redux';

import { postActions } from '../../store/actions';
import { postConstants } from '../../store/constants';

import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';

import './EditPostPage.scss';


class EditPostPage extends React.Component {
    constructor(props) {
        super(props);

        const post_id = this.props.match.params.post_id;
        this.props.dispatch(postActions.getDetail(post_id));

        
        this.state = {
            post_id: post_id,
            title: '',
            url: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // Make sure we initially set the form fields, if we come here via a React-Link
        const post  = this.selectedPost();
        if (post && post.title && post.url) {
            this.setState({
                title: post.title,
                url: post.url
            })
        }
    }
 
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.initialLoadHappened !== this.props.initialLoadHappened) {
             // Make sure we initially set the form fields, if we come here via a direct Browser Link
            const post = this.selectedPost();
            if (post && post.title && post.url) {
                this.setState({
                    title: post.title,
                    url: post.url
                })
            }
        }
    }

    selectedPost() {
        const { posts } = this.props;
        const { post_id} = this.state;
        if (!posts) {
            return null;
        }
        return posts.find(p => post_id === p._id);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { title, url, post_id } = this.state;
        const { dispatch } = this.props;
        if (this.handleLocalErrors()) {
            const redirectToDetailPageOnSuccess = true;
            dispatch(postActions.updatePost(post_id, title, url, redirectToDetailPageOnSuccess));
        }
    }

    handleLocalErrors() {
        const { title, url, post_id } = this.state;
        const { dispatch } = this.props;

        let validationErrors = [];

        if (!title) {
            validationErrors.push({
                value: title,
                msg: 'Title is required.',
                param: 'title',
                location: 'local'
            })
        }

        if (!url) {
            validationErrors.push({
                value: url,
                msg: 'A url is required.',
                param: 'url',
                location: 'local'
            })
        }

        if (validationErrors.length > 0 ) {
            function failure(error) { return { type: postConstants.UPDATE_POST_REQUEST_FAILED, error, post_id } }
            dispatch(failure({
                status: 0,
                message: 'Local Validation Error.',
                data: validationErrors
            }));
            return false
        }

        return true; 
    }

    errorsForField(name) {
        const { errors } = this.props;

        if (!errors) {
            return '';
        }
        if (!errors.data) {
            return '';
        }
        if (!errors.data.length > 0) {
            return '';
        }
        return errors.data
            .filter(e => e.param === name)
            .map(e => <div className="error" key={e.msg}>{e.msg}</div>)
    }

    showLocalErrorMessages() {
        const { errors } = this.props;
        // error messages from the clients should only be shown, if there are now errors from the server yet
        return !errors || !errors.data || !errors.data.length;
    }

    showFieldIndepenentErrorMessage() {
        const { errors } = this.props;
        // only show the field-independet error message, if the are none related to a specific field
        return errors && errors.message && (!errors.data || !errors.data.length);
    }

    render() {
        const { initialLoadHappened, errors } = this.props;
        const { title, url, submitted } = this.state;
        const post = this.selectedPost();
        const loading = post && post.loading;

        return (
            <div className="view-edit-post-page">
                <Navbar/>
                <div className={`site-container ${loading ? 'loading' : ''}`}>
                {initialLoadHappened && post &&
                    <div>
                        <h2>Edit Your Post</h2>
                        <div className="tagline">Change the title or url of your post.</div>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !title ? ' has-error' : '')}>
                                <label htmlFor="title">Title</label>
                                <input type="text" className="form-control" name="title" value={title} onChange={this.handleChange}/>
                                {
                                    this.errorsForField('title')
                                }
                            </div>
                            <div className={'form-group' + (submitted && !url ? ' has-error' : '')}>
                                <label htmlFor="url">URL</label>
                                <input type="text" className="form-control" name="url" value={url} onChange={this.handleChange} />
                                {
                                    this.errorsForField('url')
                                }
                            </div>
                            <div className="form-group">
                                {!loading &&
                                    <input type="submit" className="form-control" name="submit" value="Update"/>
                                }
                                {loading &&
                                    <input type="submit" className="form-control" name="submit" value="Updating ..." disabled/>
                                }
                            </div>
                        </form>
                        <div className="error-container">
                            {this.showFieldIndepenentErrorMessage() && 
                                <div className="error">{errors.message}</div>
                            }
                        </div>
                    </div>
                }
                </div>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { initialLoadHappened, errors, posts } = state.posts;
    return {
        initialLoadHappened,
        errors,
        posts
    };
}

const connectedEditPostPage = connect(mapStateToProps)(EditPostPage);
export { connectedEditPostPage as EditPostPage };
