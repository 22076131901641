import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as timeago from 'timeago.js';
import { UrlParser } from '../helpers';
import { history } from '../helpers';

import { postActions } from '../store/actions';
import { upvoteActions } from '../store/actions';

import './PostRow.scss';

class PostRow extends React.Component {
    constructor(props) {
        super(props);

        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
        this.handleUpvoteClicked = this.handleUpvoteClicked.bind(this);
        this.handleUnvoteClicked = this.handleUnvoteClicked.bind(this);
      }

    getLinkHostName() {
        const { post } = this.props;
        const url = post ? post.url : null;

        if(!url) {
            return '';
        }
        return UrlParser(url).hostname;
    }

    handleDeleteClicked(e) {
        e.preventDefault();
        const { post, dispatch } = this.props;
        
        dispatch(postActions.deletePost(post));
    }

    handleUpvoteClicked(e) {
        e.preventDefault();
        const { post, loggedIn, dispatch } = this.props;

        if (!loggedIn) {
            const postDetailLink = encodeURIComponent(`/posts/${post._id}`)
            history.push(`/login?redirect=${postDetailLink}`)
        } else {
            dispatch(upvoteActions.upvotePost(post))
        }
    }

    handleUnvoteClicked(e) {
        e.preventDefault();
        const { post, loggedIn, dispatch } = this.props;

        if (!loggedIn) {
            const postDetailLink = encodeURIComponent(`/posts/${post._id}`)
            history.push(`/login?redirect=${postDetailLink}`)
        } else {
            dispatch(upvoteActions.unvotePost(post));
        }
    }

    render() {
        const { rank, post, user } = this.props;

        return (
            <div className={`component-post-row ${post ? '' : 'hidden'}`}>
                {post &&
                    <div className={`row-content ${post.loading ? 'loading' : ''}`}>
                        <div className="top-row">
                            {rank &&
                                <div className="rank">
                                    {rank}.
                                </div>
                            }
                            <div className="upvote">
                                {!post.userHasUpvoted &&
                                    <img src="/icon_arrow_up.png" alt="Upvote Button" onClick={this.handleUpvoteClicked}/>
                                }
                            </div>
                            <div className="title">
                                <a href={post.url} target="_blank" rel="noopener noreferrer">{post.title}</a>
                                <span className="website">
                                    <a href={`http://${this.getLinkHostName()}`} target="_blank" rel="noopener noreferrer">({this.getLinkHostName()})</a>
                                </span>
                            </div>

                        </div>
                        <div className={`bottom-row ${rank ? 'has-rank' : ''}`}> 
                            {post.upvotes} points by {post.username} {timeago.format(post.createdAt)}
                            {post.userHasUpvoted &&
                                <span className="divider">|</span>
                            }
                            {post.userHasUpvoted &&
                                <span className="unvote" onClick={this.handleUnvoteClicked}>
                                    unvote
                                </span>
                            }
                            <span className="divider">|</span>
                            <Link className="comment" to={`/posts/${post._id}`}>
                                {post.comments && post.comments.length > 0
                                    ? <span>{post.comments.length} comments</span>
                                    : <span>discuss</span>
                                }
                            </Link>
                            {user && user.username === post.username &&
                                <span>
                                    <span className="divider">|</span>
                                    <Link className="edit" to={`/posts/${post._id}/edit`}>
                                        edit
                                    </Link>
                                    <span className="divider">|</span>
                                    <Link to={`${window.location.href}/#`} onClick={this.handleDeleteClicked}>
                                        delete
                                    </Link>
                                </span>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    return {
        user,
        loggedIn
    };
}

const connectedPostRow = connect(mapStateToProps)(PostRow);
export { connectedPostRow as PostRow }; 
