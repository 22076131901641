import React from 'react';

import './Footer.scss';

class Footer extends React.Component {

    render() {
        return (
            <footer className="component-footer">
                <div>
                    The CDTM's mission is to
                    <strong> connect, educate and empower the innovators of tomorrow </strong> 
                    through our add-on study program.&nbsp;
                    <a href="https://www.cdtm.de/about/" target="_blank" rel="noopener noreferrer">Applications</a> are open twice a year.
                </div>
                <div>
                    This is a demo website developed as part of the elective course "Web Development" in summer term 2020.
                </div>
                <div>
                    <a href="https://www.cdtm.de/contact/" target="_blank" rel="noopener noreferrer">Imprint</a>
                    <span className="divider">|</span>
                    <a href="https://www.cdtm.de/legal/" target="_blank" rel="noopener noreferrer">Data Privacy</a>
                </div>

            </footer>
        );
    }
}

export { Footer }; 
