import { postConstants } from '../constants';

const initialState = { creating: false, errors: {}, post: null };

export function createPost(state = initialState, action) {
  switch (action.type) {
    case postConstants.CREATE_POST_REQUEST_INITIATED:
      return {...state, ...{
        creating: true
      }};
    case postConstants.CREATE_POST_REQUEST_SUCCEEDED:
      return {...state, ...{
        creating: false,
        post: action.post,
        errors: {}
      }};
    case postConstants.CREATE_POST_REQUEST_FAILED:
      return {...state, ...{
        creating: false,
        errors: action.error
      }};
    default:
      return state
  }
}
