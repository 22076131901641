export function UrlParser(urlString) {
    const parser = document.createElement('a');
    parser.href = urlString;

    return {
        protocol: parser.protocol,  // => "http:"
        hostname: parser.hostname,  // => "example.com"
        port: parser.port,          // => "3000"
        pathname: parser.pathname,  // => "/pathname/"
        search: parser.search,      // => "?search=test"
        hash: parser.hash,          // => "#hash"
        host: parser.host           // => "example.com:3000"
    }
}
