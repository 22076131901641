export const postConstants = {
    CREATE_POST_REQUEST_INITIATED: 'CREATE_POST_REQUEST_INITIATED',
    CREATE_POST_REQUEST_SUCCEEDED: 'CREATE_POST_REQUEST_SUCCEEDED',
    CREATE_POST_REQUEST_FAILED: 'CREATE_POST_REQUEST_FAILED',

    GET_POSTS_REQUEST_INITIATED: 'GET_POSTS_REQUEST_INITIATED',
    GET_POSTS_REQUEST_SUCCEEDED: 'GET_POSTS_REQUEST_SUCCEEDED',
    GET_POSTS_REQUEST_FAILED: 'GET_POSTS_REQUEST_FAILED',

    UPDATE_POST_REQUEST_INITIATED: 'UPDATE_POST_REQUEST_INITIATED',
    UPDATE_POST_REQUEST_SUCCEEDED: 'UPDATE_POST_REQUEST_SUCCEEDED',
    UPDATE_POST_REQUEST_FAILED: 'UPDATE_POST_REQUEST_FAILED',

    DELETE_POST_REQUEST_INITIATED: 'DELETE_POST_REQUEST_INITIATED',
    DELETE_POST_REQUEST_SUCCEEDED: 'DELETE_POST_REQUEST_SUCCEEDED',
    DELETE_POST_REQUEST_FAILED: 'DELETE_POST_REQUEST_FAILED',
}
