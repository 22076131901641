export const authConstants = {
    REGISTER_REQUEST_INITIATED: 'REGISTER_REQUEST_INITIATED',
    REGISTER_REQUEST_SUCCEEDED: 'REGISTER_REQUEST_SUCCEEDED',
    REGISTER_REQUEST_FAILED: 'REGISTER_REQUEST_FAILED',

    VERIFY_REQUEST_INITIATED: 'VERIFY_REQUEST_INITIATED',
    VERIFY_REQUEST_SUCCEEDED: 'VERIFY_REQUEST_SUCCEEDED',
    VERIFY_REQUEST_FAILED: 'VERIFY_REQUEST_FAILED',

    SEND_NEW_CODE_REQUEST_INITIATED: 'SEND_NEW_CODE_REQUEST_INITIATED',
    SEND_NEW_CODE_REQUEST_SUCCEEDED: 'SEND_NEW_CODE_REQUEST_SUCCEEDED',
    SEND_NEW_CODE_REQUEST_FAILED: 'SEND_NEW_CODE_REQUEST_FAILED',

    LOGIN_REQUEST_INITIATED: 'LOGIN_REQUEST_INITIATED',
    LOGIN_REQUEST_SUCCEEDED: 'LOGIN_REQUEST_SUCCEEDED',
    LOGIN_REQUEST_FAILED: 'LOGIN_REQUEST_FAILED',

    // logout is a local operation – delete the JWT – and should always succeed
    LOGOUT_SUCCEEDED: 'LOGOUT_SUCCEEDED', 

    UPDATE_PW_REQUEST_INITIATED: 'UPDATE_PW_REQUEST_INITIATED',
    UPDATE_PW_REQUEST_SUCCEEDED: 'UPDATE_PW_REQUEST_SUCCEEDED',
    UPDATE_PW_REQUEST_FAILED: 'UPDATE_PW_REQUEST_FAILED',

    FORGOT_PW_REQUEST_INITIATED: 'FORGOT_PW_REQUEST_INITIATED',
    FORGOT_PW_REQUEST_SUCCEEDED: 'FORGOT_PW_REQUEST_SUCCEEDED',
    FORGOT_PW_REQUEST_FAILED: 'FORGOT_PW_REQUEST_FAILED',

    RESET_PW_REQUEST_INITIATED: 'RESET_PW_REQUEST_INITIATED',
    RESET_PW_REQUEST_SUCCEEDED: 'RESET_PW_REQUEST_SUCCEEDED',
    RESET_PW_REQUEST_FAILED: 'RESET_PW_REQUEST_FAILED',
};
