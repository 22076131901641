import { postConstants } from '../constants';
import { postService } from '../services';
import { history } from '../../helpers';

export const postActions = {
    createPost,
    getAll,
    getDetail,
    deletePost,
    updatePost,
};

function createPost(title, url, redirectToDetailPageOnSuccess) {
    return dispatch => {
        dispatch(request());

        postService.createPost(title, url)
            .then(
                response => {
                    const post = response.data;
                    dispatch(success(post));
                    if(redirectToDetailPageOnSuccess && post && post._id) {
                        history.push(`/posts/${post._id}`)
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: postConstants.CREATE_POST_REQUEST_INITIATED } }
    function success(post) { return { type: postConstants.CREATE_POST_REQUEST_SUCCEEDED, post } }
    function failure(error) { return { type: postConstants.CREATE_POST_REQUEST_FAILED, error } }
}


function getAll(page, resultsPerPage, newest) {
    return dispatch => {
        dispatch(request());

        postService.getAll(page, resultsPerPage, newest)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: postConstants.GET_POSTS_REQUEST_INITIATED } }
    function success(postData) { return { type: postConstants.GET_POSTS_REQUEST_SUCCEEDED, postData } }
    function failure(error) { return { type: postConstants.GET_POSTS_REQUEST_FAILED, error } }
}

function getDetail(post_id) {
    return dispatch => {
        dispatch(request());

        postService.getDetail(post_id)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };
    
    function request() { return { type: postConstants.GET_POSTS_REQUEST_INITIATED } }
    function success(post) { return { type: postConstants.UPDATE_POST_REQUEST_SUCCEEDED, post } } 
    function failure(error) { return { type: postConstants.GET_POSTS_REQUEST_FAILED, error } }
}

function deletePost(post) {
    return dispatch => {
        dispatch(request(post._id));

        postService.deletePost(post._id)
            .then(
                response => {
                    dispatch(success(post._id));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error, post._id));
                }
            );
    };

    function request(post_id) { return { type: postConstants.DELETE_POST_REQUEST_INITIATED, post_id } }
    function success(post_id) { return { type: postConstants.DELETE_POST_REQUEST_SUCCEEDED, post_id } }
    function failure(error, post_id) { return { type: postConstants.DELETE_POST_REQUEST_FAILED, error, post_id } }
}

function updatePost(post_id, title, url, redirectToDetailPageOnSuccess) {
    return dispatch => {
        dispatch(request(post_id));

        postService.updatePost(post_id, title, url)
            .then(
                response => {
                    const post = response.data;
                    dispatch(success(post));
                    if(redirectToDetailPageOnSuccess && post && post._id) {
                        history.push(`/posts/${post._id}`)
                    }
                },
                error => {
                    dispatch(failure(error, post_id));
                }
            );
    };

    function request(post_id) { return { type: postConstants.UPDATE_POST_REQUEST_INITIATED, post_id } }
    function success(post) { return { type: postConstants.UPDATE_POST_REQUEST_SUCCEEDED, post } }
    function failure(error, post_id) { return { type: postConstants.UPDATE_POST_REQUEST_FAILED, error, post_id } }
}
