import { postConstants } from '../constants';
import { upvoteService } from '../services';

export const upvoteActions = {
    upvotePost,
    unvotePost
};


// -----------------
// private functions
// -----------------

function getOptimisticallyUpdatedPost(post, userWillHaveUpvoted) {
    let expectedPost = JSON.parse(JSON.stringify(post))
    expectedPost.userHasUpvoted = userWillHaveUpvoted;
    if (post.userHasUpvoted !== userWillHaveUpvoted) {
        const exptectedUpvoteDelta = userWillHaveUpvoted ? 1 : -1;
        expectedPost.upvotes = post.upvotes + exptectedUpvoteDelta;
    }
    return expectedPost;
}


// -----------------
// actions
// -----------------

function upvotePost(post) {

    return dispatch => {
        // Let's do some optimistic UI magic here:
        // We update the post right away with the expectation that it works
        // and later with the actual value from the server.
        dispatch(updatePost(getOptimisticallyUpdatedPost(post, true)));

        upvoteService.upvotePost(post._id)
            .then(
                response => {
                    // if the upvote succeeded still update the post with the one from the server
                    const updatedPost = response.data;
                    dispatch(updatePost(updatedPost));
                },
                error => {
                    // if the upvote didn't succeed roll back the change
                    dispatch(updatePost(getOptimisticallyUpdatedPost(post, true)));
                }
            );
    };

    function updatePost(post) { return { type: postConstants.UPDATE_POST_REQUEST_SUCCEEDED, post } }
}

function unvotePost(post) {
    return dispatch => {
        // Let's do some optimistic UI magic here:
        // We update the post right away with the expectation that it works
        // and later with the actual value from the server.
        dispatch(updatePost(getOptimisticallyUpdatedPost(post, false)));

        upvoteService.unvotePost(post._id)
            .then(
                response => {
                    // if the upvote succeeded still update the post with the one from the server
                    const updatedPost = response.data;
                    dispatch(updatePost(updatedPost));
                },
                error => {
                    // if the upvote didn't succeed roll back the change
                    dispatch(updatePost(getOptimisticallyUpdatedPost(post, true)));
                }
            );
    };

    function updatePost(post) { return { type: postConstants.UPDATE_POST_REQUEST_SUCCEEDED, post } }
}
