import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../helpers';
import { postActions } from '../../store/actions';

import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { PostRow } from '../../components/PostRow';

import qs from 'qs';

import './HomePage.scss';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        this.state = {
            page: parseInt(query.page) || 1,
            resultsPerPage: parseInt(query.results) || 25,
            newest: parseInt(props.newest) || 0,
        };
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    componentDidMount() {
        this.requestPosts();
        // make sure everytime the query parameter of the url changes,
        // we update the state and load new posts from the api
        const unregisterListener = this.props.history.listen((location) => {
            const query = qs.parse(location.search, { ignoreQueryPrefix: true });
            this.setState({
                page: parseInt(query.page) || 1,
                resultsPerPage: parseInt(query.results) || 25,
                newest: parseInt(this.props.newest) || 0,
            }, () => {
                this.requestPosts();
            })
        });

        this.setState({unregisterListener});
    }

    componentWillUnmount() {
        if (this.state.unregisterListener) {
            this.state.unregisterListener();
        }
    }

    requestPosts() {
        const { page, resultsPerPage, newest} = this.state;
        this.props.dispatch(postActions.getAll(page, resultsPerPage, newest));
    }

    hasPosts() {
        const { posts } = this.props;
        return posts && posts.posts && posts.posts.length >0;
    }

    shouldHideView() {
        const { posts } = this.props;
        return !posts.initialLoadHappened;
    }

    handleLoadMore(e) {
        e.preventDefault();
        
        const { posts, location } = this.props;


        if (!posts || !posts.posts || !posts.posts.length || posts.loading) {
            return;
        }
 
        const newUrl = `${location.pathname}?page=${this.state.page + 1}&?results=${this.state.resultsPerPage}`;
        history.push(newUrl);
    }

    renderPosts() {
        const { posts } = this.props;

        if (!posts) {
            return '';
        }
        if (!posts.posts) {
            return '';
        }
        if (!posts.posts.length > 0) {
            return '';
        }
        let postRank = posts.offset+1;
        return posts.posts
            .filter(p => !p.hidden)
            .map(p => <PostRow post={p} rank={postRank++} key={p._id} />)
    }

    render() {
        const { posts } = this.props;

        return (
            <div className={`view-home-page ${this.shouldHideView() ? 'hidden' : ''}`}>
                <Navbar/>
                <div className="site-container post-list-container">
                    {posts && posts.posts &&
                        <div className="post-list">                            
                            {
                                this.renderPosts()
                            }
                        </div>
                    }
                    {posts && posts.posts && posts.posts.length>=this.state.resultsPerPage &&
                        <div className="button-container"> 
                            <div className="button" onClick={this.handleLoadMore}>
                                More ...
                            </div>
                        </div>
                    }
                </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { login, posts } = state;
    return {
        user: login.user,
        posts: posts
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
