import { postConstants } from '../constants';

const initialState = { initialLoadHappened: false, loading: false, errors: {}, page: 1, posts: [] };

// private helper functions
function markPostAsLoading(loading, posts, post_id) {

  const updatedPosts = posts.map(p => {
    if (p._id === post_id) {
      return {...p, loading: loading}
    } else {
      return p;
    }
  })
  return updatedPosts;
}

function updatePost(posts, post) {
let updatedPost = post;
let hasFoundPost = false;
let updatedPosts = posts.map(p => {
  if (p._id === updatedPost._id) {
    hasFoundPost = true;
    return updatedPost;
  } else {
    return p;
  }
})
// make sure we also track posts, but it isn't shown by accident in the list view 
if (!hasFoundPost) {
  updatedPost.hidden = true;
  updatedPosts.push(updatedPost);
}
return updatedPosts;
}

function deletePost(posts, post_id) {
  return posts.filter(p => p._id !== post_id);
}

export function posts(state = initialState, action) {
  switch (action.type) {
    case postConstants.GET_POSTS_REQUEST_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case postConstants.GET_POSTS_REQUEST_SUCCEEDED:
      const { page, data, offset } = action.postData;
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: {},
        posts: data,
        offset: offset,
        page: page
      }};
    case postConstants.GET_POSTS_REQUEST_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
      case postConstants.UPDATE_POST_REQUEST_INITIATED:
        return {...state, ...{ 
          loading: true,
          posts: markPostAsLoading(true, state.posts, action.post_id)
        }};
      case postConstants.UPDATE_POST_REQUEST_SUCCEEDED:
        return {...state, ...{ 
          initialLoadHappened: true,
          loading: false,
          posts: updatePost(state.posts, action.post)
        }};
      case postConstants.UPDATE_POST_REQUEST_FAILED:
        return {...state, ...{ 
          initialLoadHappened: true,
          loading: false,
          errors: action.error,
          posts: markPostAsLoading(false, state.posts, action.post_id)
        }};
      case postConstants.DELETE_POST_REQUEST_INITIATED:
        return {...state, ...{ 
          loading: true,
          posts: markPostAsLoading(true, state.posts, action.post_id)
        }};
      case postConstants.DELETE_POST_REQUEST_SUCCEEDED:
          return {...state, ...{ 
            loading: false,
            posts: deletePost(state.posts, action.post_id)
          }};
      case postConstants.DELETE_POST_REQUEST_FAILED:
          return {...state, ...{ 
            loading: false,
            errors: action.error,
            posts: markPostAsLoading(false, state.posts, action.post_id)
          }};
    default:
      return state
  }
}
