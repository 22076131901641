import React from 'react';
import { connect } from 'react-redux';

import * as timeago from 'timeago.js';

import './CommentRow.scss';

class CommentRow extends React.Component {

    render() {
        const { comment } = this.props;

        return (
            <div className={`component-comment-row ${comment ? '' : 'hidden'}`}>
                {comment &&
                    <div className="row-content">
                        <div className="top-row">
                            <div className="user">
                                {comment.username} {timeago.format(comment.createdAt)}
                            </div>
                            {comment.userHasUpvoted &&
                                <div className="unvote" onClick={this.handleUnvoteClicked}>
                                    unvote
                                </div>
                            }
                        </div>
                        <div className={`bottom-row`}> 
                            {comment.text}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn } = state.login;
    return {
        loggedIn
    };
}

const connectedNavbar = connect(mapStateToProps)(CommentRow);
export { connectedNavbar as CommentRow }; 
