import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

let middleware = [];

middleware.push(thunkMiddleware);

// only add logger middleware in development 
if (process.env.NODE_ENV === `development`) {
    const loggerMiddleware = createLogger();
    middleware.push(loggerMiddleware);
}

export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);
