import React from 'react';
import { connect } from 'react-redux';
import { postActions } from '../../store/actions';
import { postConstants } from '../../store/constants';

import qs from 'qs';

import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';

import './NewPostPage.scss';

class NewPostPage extends React.Component {
    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

        this.state = {
            title: query.title || '',
            url: query.url || '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        this.submit();
    }

    submit() {
        const { title, url } = this.state;
        const { dispatch } = this.props;
        if (this.handleLocalErrors()) {
            dispatch(postActions.createPost(title, url, true))
        }
    }

    handleLocalErrors() {
        const { title, url } = this.state;
        const { dispatch } = this.props;

        let validatioErrors = [];

        if (!title) {
            validatioErrors.push({
                value: title,
                param: 'title',
                msg: 'Title is required.',
                location: 'body'
            });
        }

        if(!url) {
            validatioErrors.push({
                value: url,
                param: 'url',
                msg: 'URL is required.',
                location: 'body'
            });
        }

        if (validatioErrors.length>0) {
            function failure(error) { return { type: postConstants.CREATE_POST_REQUEST_FAILED, error } }
            dispatch(failure({
                status: 0,
                message: 'Local Validation Error.',
                data: validatioErrors
            }));
            return false;
        }
        return true;
    }

    errorsForField(name) {
        const { errors } = this.props;

        if (!errors) {
            return '';
        }

        if (!errors.data) {
            return '';
        }

        if (!errors.data.length > 0) {
            return '';
        }

        return errors.data
            .filter(e => e.param === name)
            .map(e => <div className="error" key={e.msg}>{e.msg}</div>)
    }

    showLocalErrorMessages() {
        const { errors } = this.props;
        // error messages from the clients should only be shown, if there are now errors from the server yet
        return !errors || !errors.data || !errors.data.length;
    }

    showFieldIndepenentErrorMessage() {
        const { errors } = this.props;
        // only show the field-independet error message, if the are none related to a specific field
        return errors && errors.message && (!errors.data || !errors.data.length);
    }

    render () {
        const { creating, errors } = this.props;
        const { submitted, title, url } = this.state;

        return (
            <div className="view-new-post-page">
                <Navbar/>
                <div className="site-container">
                    <h2>Create a New Post</h2>
                    <div className="tagline">Create a new post and share interesting news with the community.</div>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !title ? ' has-error' : '')}>
                            <label htmlFor="title">Title</label>
                            <input type="text" className="form-control" name="title" value={title} onChange={this.handleChange}/>
                            {
                                this.errorsForField('title')
                            }
                        </div>
                        <div className={'form-group' + (submitted && !url ? ' has-error' : '')}>
                            <label htmlFor="url">URL</label>
                            <input type="text" className="form-control" name="url" value={url} onChange={this.handleChange}/>
                            {
                                this.errorsForField('url')
                            }
                        </div>
                        <div className="form-group">
                            {!creating && 
                                <input type="submit" className="form-control" name="login" value="Submit"/>
                            }
                            {creating &&
                                <input type="submit" className="form-control" name="login" value="Submit ..." disabled/>
                            }

                        </div>
                    </form>
                    <div className="error-container">
                        {this.showFieldIndepenentErrorMessage() && 
                            <div className="error">{errors.message}</div>
                        }
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { creating, errors, post } = state.createPost;
    return {
        creating,
        errors,
        post
    }
}


const connectedNewPostPage = connect(mapStateToProps)(NewPostPage);
export { connectedNewPostPage as NewPostPage };
